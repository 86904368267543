<template>
  <b-card>
    <div class="custom-container">
      <b-row>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <div class="d-flex flex-column align-items-start">
                <h2 class="card-title">
                  <b>REGISTRO SERVICIOS</b>
                </h2>
              </div>
            </template>
            <div class="px-5 py-3">
              <div class="row" style="gap: 1rem">
                <div class="col-12 col-md-10">
                  <!-- linar-style cursor glow -->
                  <CardTable :data="dataCards" />
                </div>
                <!-- btn new Gps -->
                <div class="col-12 col-md">
                  <BtnAddCardTable
                    v-b-modal.nuevoServicio
                    text="Nuevo Servicio"
                  />
                </div>
              </div>
            </div>
            <!-- table registro servicios-->
            <div class="px-4 mt-3">
              <table
                id="datatable_registroServicios"
                class="table table-bordered table-responsive-md table-striped text-center"
              >
                <thead>
                  <tr>
                    <th>Placa</th>
                    <th>Tipo Servicio</th>
                    <th>Descripción</th>
                    <th>Fecha Inicio</th>
                    <th>Fecha Fin</th>
                    <th>Estado</th>
                    <th>Finalizar Servicio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in listaItems" :key="item.id">
                    <!-- placa -->
                    <td>
                      {{ item.placa }}
                    </td>
                    <!-- tipo servicio -->
                    <td>
                      {{ item.tipoServicio.descripcion }}
                    </td>
                    <!-- descripcion -->
                    <td>{{ item.descripcion }}</td>
                    <!-- fecha inicio -->
                    <td>{{ item.fechaInicio | timeSince }}</td>
                    <!-- fecha fin -->
                    <td>{{ item.fechaFin | timeSince }}</td>
                    <!-- estado -->
                    <td>
                      <h4>
                        <b-badge
                          :variant="`${item.estado ? 'success' : 'warning'} `"
                        >
                          <i
                            :class="`fas ${
                              item.estado ? 'fa-circle-check' : 'fa-clock'
                            }`"
                          ></i>
                          {{ item.estado ? "Finalizado" : "En Proceso" }}
                        </b-badge>
                      </h4>
                    </td>
                    <!-- finalizar servicio -->
                    <td>
                      <template>
                        <div
                          class="d-flex align-items-center justify-content-center"
                        >
                          <vs-switch
                            v-model="item.estado"
                            :disabled="item.estado"
                            @input="finalizarServicio(index, item)"
                          >
                            <template #off>
                              <i class="fas fa-xmark"></i>
                            </template>
                            <template #on>
                              <i class="fas fa-check"></i>
                            </template>
                          </vs-switch>
                        </div>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- modal add servicio -->
            <b-modal
              title="Nuevo Servicio"
              id="nuevoServicio"
              size="lg"
              hide-footer
              hide-header
              body-class="body-modal-custom"
            >
              <add_servicio :sharedService="sharedService" />
            </b-modal>
          </card>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { core } from "../../../config/pluginInit";
import moment_timezone from "moment-timezone";
import CardTable from "../../../components/cardTable/CardTable.vue";
import BtnAddCardTable from "../../../components/btnCardTable/btnAddCardTable.vue";
import add_servicio from "./add_servicio.vue";
const Swal = require("sweetalert2");
export default {
  name: "registroServicios",
  components: {
    CardTable,
    BtnAddCardTable,
    add_servicio,
  },
  data() {
    return {
      persona: {},
      dataTableInstance: null,
      listaVehiculos: [],
      listaItems: [
        {
          id: null,
          personaId: null,
          placaId: "",
          placa: "",
          tipoServicioId: null,
          descripcion: "",
          fechaInicio: "",
          fechaFin: "",
          estado: false,
          tipoServicio: {},
        },
      ],
      dataCards: [
        {
          title: "Finalizados",
          value: () => this.listaItems.filter((s) => s.fechaFin).length,
          iconName: "circle-check",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            this.dataTableInstance.search("Finalizado").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "En Proceso",
          value: () => this.listaItems.filter((s) => !s.fechaFin).length,
          iconName: "clock",
          color: "rgba(254, 114, 28, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            // console.log("Activated");
            this.dataTableInstance.search("En Proceso").draw();
          },
          onDeactivate: () => {
            // console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.listaItems = [];
    await this.getVehiculosByEmpresa();
    await this.getData();
    core.index();

    if (window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
      window.$("#datatable_registroServicios").DataTable().destroy();
    }
    this.dataTableInstance = window
      .$("#datatable_registroServicios")
      .DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });

    this.$isLoading(false);
  },
  methods: {
    getPlacaVehiculo(vehiculoId) {
      return this.listaVehiculos.find((v) => v.id === vehiculoId).placa;
    },
    async getVehiculosByEmpresa() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Vehiculo/VehiculosPorEmpresa/" + this.persona.empresa.id,
        });
        // console.log("list vehiculos...", res);
        if (res.length > 0) {
          this.listaVehiculos = res;
        } else {
          this.listaVehiculos = [];
        }
      } catch (error) {
        this.listaVehiculos = [];
        console.log("err", error);
      }
    },
    async sharedService(newService) {
      // console.log(newService, "desde padre");

      // cerrar modal
      this.$bvModal.hide("nuevoServicio");

      this.listaItems = [...this.listaItems, newService];

      if (window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
        window.$("#datatable_registroServicios").DataTable().destroy();
      }

      setTimeout(() => {
        this.dataTableInstance = window
          .$("#datatable_registroServicios")
          .DataTable({
            language: {
              url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
            },
          });
      }, 100);
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "RegistroServicios/ListByPersona/" + this.persona.id,
        });
        // console.log("list registroServicios...", res);
        if (res.success) {
          this.listaItems = res.data.map((service) => {
            return {
              ...service,
              placa: this.getPlacaVehiculo(service.placaId),
              estado: service.fechaFin ? true : false,
            };
          });
          // console.log(this.listaItems);
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
    async sendFinalizarServicio(request) {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "RegistroServicios/FinalizarServicio/",
          data: request,
        });
        // console.log("finalizarServicio...", res);
        return res;
      } catch (error) {
        console.log("err", error);
        return null;
      }
    },
    async finalizarServicio(rowIndex, item) {
      // console.log(rowIndex, item);
      if (item.estado == true) {
        Swal.fire({
          title: "Seguro de finalizar?",
          text: "Una vez finalizado no podrá revertir los cambios!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#186bdd",
          cancelButtonColor: "rgb(242, 19, 93)",
          confirmButtonText: "Si, finalizar!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          // console.log(result);
          if (result.isConfirmed) {
            const response = await this.sendFinalizarServicio({
              ServicioId: item.id,
              FechaFin: this.$moment
                .tz(new Date(), "America/Bogota")
                .format("YYYY-MM-DDTHH:mm"),
            });

            if (response.success) {
              this.$set(this.listaItems, rowIndex, {
                ...item,
                fechaFin: this.$moment
                  .tz(new Date(), "America/Bogota")
                  .format("YYYY-MM-DDTHH:mm"),
                estado: true,
              });
              setTimeout(() => {
                this.dataTableInstance.row(rowIndex).invalidate().draw();
              }, 100);
              this.$vs.notification({
                //duration: "none",
                progress: "auto",
                icon: `<i class='fas fa-circle-check'></i>`,
                color: "success",
                position: "top-right",
                title: "Servicio Finalizado!",
              });
            }
          } else {
            item.estado = false;
          }
        });
      }
    },
  },
  filters: {
    timeSince(dateString, timezone = "America/Bogota") {
      const dateInBogota = moment_timezone.tz(dateString, timezone);
      return dateString ? dateInBogota.format("YYYY-MM-DD hh:mm A") : "";
    },
  },
};
</script>

<style scoped></style>
