<!--btn add card table -->
<template>
  <vs-button
    @click="handleClick"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
     
    :success="variant === 'success'"
    :danger="variant === 'danger'"
    :warn="variant === 'warn'"
    class="btn-add"
    :to="`${url}`"
  >
    <i
      :class="`fas fa-${icon} position-absolute ${isHovered ? 'fa-beat' : ''}`"
      style="right: 0; top: 0; font-size: 1.5rem"
    ></i>
    {{ text }}
  </vs-button>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "circle-plus",
    },
    text: {
      type: String,
      default: "Click Me!",
    },
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  methods: {
    handleClick() {
      // Emitir un evento personalizado cuando se hace clic en el botón
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.btn-add {
  position: relative;
  height: 81px;
  width: 100%;
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0;
  overflow: visible;
}
</style>
